@import '~antd/dist/antd.less';

@layout-header-background: #096dd9;
@primary-color: #0144e4;

body {
  background: #ffffff;
}

.ant-layout {
  background: white;
}

.ant-btn{
  border-radius: 4px
}

.top-header{
  padding: 0;
  position: fixed;
  z-index: 900;
  width: 100%;
  background: #ffffff;
  border-bottom: solid;
  border-bottom-color: #e9eaf0;
  border-bottom-width: 1px;
  box-shadow: 0 3px 10px 0 rgba(8, 15, 52, 0.04);
}

.ant-layout-content {
  background: white;
  border-style: solid;
  border-width: 1px;
  border-color: #e9eaf0;
  border-radius: 6px;
  box-shadow: 0 3px 10px 0 rgba(8, 15, 52, 0.04);
}

.menu-logo {
  width: 5em;
  margin: -8px 16px 0px 0px;
}

.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
}

.ant-layout-footer {
  background: transparent;
}

.ant-modal, .ant-modal-mask{
  animation-duration: 0ms !important;
}


.jsoneditor-poweredBy{
  visibility: hidden;
}
